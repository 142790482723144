import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import Slider from "react-slick";
import ModalCustomHead from "./customComponents/ModalCustomHead";
import Colors from '../utility/Colors';
import {homepage} from '../utility/APIRequests';
import Section from "./layouts/Section";
import LoadingOverlay from 'react-loading-overlay';

import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import {authActions, withdrawalActions, depositActions, tradeActions} from '../redux/actions';

import AutoNotification from "./layouts/AutoNotification";
import NewsTicker, {Directions} from 'react-advanced-news-ticker';
import Helper1, {notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus} from '../utility/Helper1';
import { useHistory } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { RiCheckboxIndeterminateLine, RiMore2Fill, RiCalculatorLine, RiArrowDropRightLine, RiLogoutBoxLine, RiLogoutBoxRLine, RiVolumeUpFill, RiContactsBookUploadLine, RiNewspaperLine, RiIncreaseDecreaseLine, RiFundsFill, RiGiftFill, RiSwapBoxFill, RiMapPinRangeFill, RiTelegramFill, RiWhatsappFill, RiStockFill, RiRemoteControlFill, RiInstallFill, RiKeyboardFill, RiCheckboxLine, RiArrowLeftLine, RiShareForward2Fill, RiBattery2ChargeLine, RiBatteryShareFill, RiCastLine, RiDownload2Line, RiNotification3Line, RiArrowDownCircleFill, RiMailCheckFill, RiErrorWarningFill, RiDeleteBin2Fill, RiMoneyDollarCircleLine, RiFileCopy2Fill, RiGiftLine, RiSecurePaymentLine, RiRecordCircleFill, RiShieldCrossLine, RiUser4Fill, RiExternalLinkFill, RiContactsBookFill,
RiWallet3Fill, RiCloseCircleFill, RiCalendarTodoFill, RiArrowGoBackLine, RiGasStationFill, RiBankCard2Line, RiShieldUserFill, RiTeamFill, RiTicket2Line, RiUserAddFill } from "react-icons/ri";
import { FcUpload } from "react-icons/fc";
import CustomAgo from "./customComponents/CustomAgo";

import Modal from 'react-modal';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from './layouts/Header'
import Ticker from 'react-ticker'
// import Ticker from 'nice-react-ticker';

import { PaystackButton, usePaystackPayment } from 'react-paystack'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';


import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';




const modalTitle = [
    'Withdraw',
    'Recharge',
    'Transfer',
    'Trade',
    'Calculator',
]

const menuIcons = [

    {
        images:'dash_5.png',
        info:'FAQ',
        linked: 'faq'
    },
    {
        images:'dash_6.png',
        info:'About',
        linked: 'about'
    },
    
    
    
]


const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },,
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
    };


const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };


const balanceSettings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
        }]
    };

function Home() {



    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);


    const [uploadingAvatar, setUploadingAvatar]           = useState(false);

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [whichModal, setWhichModal]   = useState(1);
    const [loadingStat, setLoadingStat]   = useState(true);

    const [withdrawAmount, setWithdrawAmount]   = useState('');
    const [withdrawalPin, setWithdrawalPin]     = useState('');
    const [walletAddress, setWalletAddress]     = useState('');
    const [whichWithdrawal, setWhichWithdrawal]     = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator]     = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput]     = useState(0);
    const [calculatorResult, setCalculatorResult]     = useState(0);

    const allDepositData = useSelector(allDepositSelect);
    const [depositAmount, setDepositAmount]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj]   = useState('');

    const [depositorName, setDepositorName]   = useState('');
    const [depositorBank, setDepositorBank]   = useState('');
    const [depositorAmountPaid, setDepositorAmountPaid]   = useState('');
    const [depositorFile, setDepositorFile]   = useState('assets/img/image.png');


    




    const [receiverEmail, setReceiverEmail]     = useState('');
    const [sendingAmount, setSendingAmount]     = useState('');


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');

    const [tradeAmount, setTradeAmount]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    
    const balanceref = useRef();
    const chooseCurrency = useRef();


    const dispatch = useDispatch();

    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }

    const currencySliderArray = (refToUse) => {
        let arrowd;

        arrowd = finance?.length > 1 && <div>
            <BsArrowLeftSquare onClick={() => refToUse.current.slickPrev()} className="cursor-pointer" size={20}/> {" "}
            <BsArrowRightSquare onClick={() => refToUse.current.slickNext()} className="cursor-pointer" size={20}/>
        </div>

        return arrowd;
    }

    const selectACurrency = () => {
        
        let value = <>

        <Slider ref={chooseCurrency} arrows={false} {...balanceSettings2}>
            {finance?.map((item, index) => <>
                <div onClick={() => {setSelectedCurrency(item.type); setSelectedCurrencyObj(item)}} style={{display: 'flex', backgroundColor: selectedCurrency == item.type?'#0ff':otherColors.card, borderRadius: 6, padding: 5, alignItems: 'center', border: "1px solid #CDD9ED"}}>
                    
                    <Images height={30} source={item.image} alt={item.name} />
                    <div className="content text-white" style={{marginLeft: 5}}>
                        <h6 className="text-white">{item.name}</h6>
                    </div>

                    <div style={{marginLeft: 'auto',}}>
                        {item.type == selectedCurrency?<RiCheckboxLine color={'#f70'} size={20}/>:<RiCheckboxIndeterminateLine color={"#fff"} size={20}/>}
                    </div>

                </div>
            </>)}
        </Slider>
        </>

        return value;
    }

    
    const referralCode = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }


    }

    const copyRefLink = () => {
        

        if((userData?.emailVerification ?? 0) == 0){
            notify('Please log in', 0)
            return;
        }

        notify('Referral Link Copied', 1)

    }

    const openWithdrawal = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        setWhichWithdrawal(0)
        setWhichModal(0)
        setOpenModal(true)
    }




const dashMenu = [
    
    {
        icon: RiBattery2ChargeLine,
        name: 'Recharge',
        color: '#9e6af8',
        click: () => openFunding(),
        id: 1,
    },

    {
        icon: RiBatteryShareFill,
        name: 'Withdraw',
        color: '#9e6af8',
        click: () => openWithdrawal(),
        id: 2,
    },
    {
        icon: RiCastLine,
        name: 'Transfer',
        color: '#9e6af8',
        click: () => transferFunding(),
        id: 3,

    },




    {
        icon: RiWallet3Fill,
        name: 'Recharge records',
        color: '#9e6af8',
        to: '/deposit-records',
        id: 4,

    },

    {
        icon: RiCalendarTodoFill,
        name: 'Withdrawal records',
        color: '#50c7cb',
        to: '/withdrawal-records',
        id: 5,

    },
    {
        icon: RiInstallFill,
        name: 'Exchange',
        color: '#000428',
        to: '/p2p',
        id: 6,

    },
    
    {
        icon: RiKeyboardFill,
        name: 'My team',
        color: '#8d8ef9',
        to: '/team',
        id: 7,

    },
    {
        icon: RiRemoteControlFill,
        name: 'Salary program',
        color: '#8d8ef9',
        to: '/salary',
        id: 8,

    },

    // {
    //     icon: RiTicket2Line,
    //     name: 'Team incomes',
    //     color: '#f571fd',
    //     to: '/team-earning',
    // }
    
]



const dashMenu2 = [
    
    {
        icon: RiStockFill,
        name: 'Agent application',
        color: '#9e6af8',
        to: '/agent',
        id: 1,
    },
    {
        icon: RiWhatsappFill,
        name: 'Whatsapp',
        color: '#123',
        to: '/agent',
        id: 2,
    },
    {
        icon: RiTelegramFill,
        name: 'Verified users forum',
        color: '#123',
        id: 3,
        click: () => goToVerifyForum(),
    },
    {
        icon: RiMapPinRangeFill,
        name: 'Office location',
        color: '#123',
        id: 4,
        to: '/address',
    },
    {
        icon: RiSwapBoxFill,
        name: 'Swap',
        color: '#123',
        id: 5,
        to: '/swap',
    },
    {
        icon: RiGiftFill,
        name: 'Welcome Bonus',
        color: '#123',
        id: 6,
        to: '/bonus',
    },
    {
        icon: RiFundsFill,
        name: 'Recharge Reward',
        color: '#123',
        id: 7,
        to: '/reinvest-bonus',
    },
    {
        icon: RiIncreaseDecreaseLine,
        name: 'Agency center',
        color: '#123',
        id: 8,
        to: '/agency-center',
    },
    {
        icon: RiNewspaperLine,
        name: 'News',
        color: '#123',
        id: 8,
        to: '/news',
    },
    {
        icon: RiNewspaperLine,
        name: 'About',
        color: '#123',
        id: 8,
        to: '/about',
    },
    {
        icon: RiContactsBookUploadLine,
        name: 'Contact',
        color: '#123',
        id: 9,
        to: '/contact',
    },
    {
        icon: RiCheckboxIndeterminateLine,
        name: 'Customer care',
        color: '#123',
        id: 10,
        to: 'https://tawk.to/chat/62fa1f2654f06e12d88eb185/1gaggjcgu',
    },

    

    
]

    
    const goToVerifyForum = () => {
        setloading(true);
        setLoadingText('Accessing verified user forumn...');

        dispatch(authActions.onLogin(`join/verified-forum`, {}, response => {
            
            setloading(false);

            if(response.code != 1){
                notify(response.msg, response.code)
            }else{
                let link = response?.data?.link;
                window.location.href = link;
            }
            

        }))
    }


    const openAffilliateWithdrawal = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        setWhichWithdrawal(1)
        setWhichModal(0)
        setOpenModal(true)
    }


    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ setLoadingStat(false); return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {
            setLoadingStat(false)
        }))

    }

    useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 2000)
        
        return () => clearTimeout(timing);
    }, [])

    if (openModal) {
      // push to history when modal opens
      window.history.pushState(null, '', window.location.href)
      
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => {}
        window.history.back()
        setOpenModal(false)
      }
    }


    const processWithdrawal = (e) => {
        
        e.preventDefault();


        if(((selectedCurrencyObj.autoConfirm == 0) && (userData.bank == null))
            || 
        ((selectedCurrencyObj.autoConfirm == 1) && (userData.trc20tetherwallet == null))){ 
            notify('Payment details not found. Please bind your payment details.');
            return false;
        }

        if((isNaN(withdrawAmount)) || (withdrawAmount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        if(withdrawalPin == ''){
            notify('Please enter your withdrawal pin', 0);
            return false;
        }

        if(selectedCurrency == ''){
            notify('Please mark a Currency', 0);
            return false;
        }

        setloading(true);
        setLoadingText('Requesting withdrawal');

        dispatch(withdrawalActions.onCreateWithdrawal({withdrawalPin, amount: withdrawAmount, currency: selectedCurrency, walletAddress}, `withdrawal/new/${whichWithdrawal}`, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                setOpenModal(false)
                setWithdrawAmount('');
                setSelectedCurrency('')
                setWithdrawalPin('');
                history.push('/withdrawal-records')
            }
            setloading(false);
        }))
    }



    const openFunding = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }

        if((userData?.trc20tetherwallet == null) && (userData?.bank == null)){
            notify('Deposit information must be set before recharge', 0)
            history.push('/personal-information');
            return;
        }

        if(userData?.withdrawalPin == 0){
            notify('Account PIN must be set before recharge', 0)
            history.push('/personal-information');
            return;
        }

        setloading(true)
        setLoadingText('Refreshing deposit records...')

        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if(response.code == 1){
                setloading(false)
                setWhichModal(1)
                setOpenModal(true)
            }
        }))

    }




    const uploadSelectedPayment = () => {
        
        setLoadingText('Uploading payment...')
        setloading(true)

        let depositorName = document.getElementById('depositorName').value;
        let depositorAmountPaid = document.getElementById('depositorAmountPaid').value;
        let depositorBank = document.getElementById('depositorBank').value;

        dispatch(depositActions.onCreateDeposit({txn_id:getPendingPayment?.[0]?.txn_id, image:depositorFile, depositorAmountPaid, depositorBank, depositorName}, "deposit/manual-deposit-upload", response => {
            setloading(false)
            notify(response.msg,  response.code)
        }))

    }



    const transferFunding = () => {
        if((userData?.emailVerification ?? 0) == 0){
            history.push('/signin')
            return;
        }
        
        setWhichModal(2)
        setOpenModal(true)

    }


    const processTransfer = (e) => {

        e.preventDefault();


        if(selectedCurrency == ''){
            notify('Select Currency', 0);
            return false;
        }
        
        setloading(true)
        setLoadingText('Transfering fund...')




        dispatch(authActions.onLogin("fund/transfer/new", {amount: sendingAmount, email: receiverEmail, pin: withdrawalPin, currency: selectedCurrency}, response => {
            if(response.code == 1){
                setReceiverEmail('')
                setSendingAmount('')
                setWithdrawalPin('')
                setSelectedCurrency('')
                setOpenModal(false)
            }
            setloading(false)
            notify(response.msg, response.code);    
        }))


    }


    const processDeposit = (e) => {
        
        e.preventDefault();

        if((isNaN(depositAmount)) || (depositAmount == 0)){
            notify('Enter amount', 0);
            return false;
        }

        if(selectedCurrency == ''){
            notify('Select Currency', 0);
            return false;
        }

        setloading(true)
        setLoadingText('Generating payment...')

        dispatch(depositActions.onCreateDeposit({amount: depositAmount, currency: selectedCurrency}, "deposit/new", response => {
            if(response.code == 1){
                setDepositAmount('')
            }else{
                notify(response.msg, response.code)
            }

            setloading(false)
            
        }))
    }

    const getPendingPayment = allDepositData?.filter((item, index) => item.status < 2);
    

    useEffect(() => {
        let pendingPayment = allDepositData?.filter((item, index) => item.status < 2);
        setDepositorName(pendingPayment?.[0]?.depositorName);
        setDepositorBank(pendingPayment?.[0]?.depositorBank);
        setDepositorAmountPaid(pendingPayment?.[0]?.depositorAmountPaid);
        setDepositorFile(pendingPayment?.[0]?.image2);

    }, [allDepositData]);


    const deleteInv = (txn_id) => {

        setloading(true)
        setLoadingText('Deleting request...')

        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            if(response.code == 1){
                // setWhichModal(1)
                // setOpenModal(true)
            }
            setloading(false)
        }))

    }


    const totalEscrow = exchangeStoreData?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.crypto_amount), 0);
    const capitals = tradeStoreData?.data?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.capital), 0);
    const compundInterest = tradeStoreData?.data?.filter((item, index) => item.active == 1 ).reduce((def, arrItem) => Number(def) + Number(arrItem.inTrade), 0);
        
    const withdrawalChargeHolder = (whichWithdrawal == 0)?'withdrawalCharge':'withdrawalChargeRef';
    const withdrawalChargePercentage = site_settings?.plans?.[0]?.mother[withdrawalChargeHolder]

    const ayoDeleObj = [
        {name:'Balance',    data: FormatOnly(finance?.[0]?.balance ?? 0), bgColor: '#0d60d8', icon: 'fa-area-chart'},
        {name:'Affiliate',  data: FormatOnly(finance?.[0]?.commission ?? 0), bgColor: '#7913e5', icon: 'fa-arrows'},
        {name:'Exchange',   data: loadingStat?<span className="fa fa-1x fa-circle-o-notch fa-spin"></span>:FormatOnly(totalEscrow ?? 0), bgColor: '#222222', icon: 'fa-anchor'},
        {name:'Interest',      data: loadingStat?<span className="fa fa-1x fa-circle-o-notch fa-spin"></span>:FormatOnly(compundInterest ?? 0), bgColor: '#753ff6', icon: 'fa-briefcase'}
    ];



    const progClickFileUpload = () => {
        if(!userData?.image){ history.push('/signin'); return;}
        hiddenFileInput.current.click();
    }


    const progClickPaymentUpload = () => {
        uploadPayment.current.click();
    }




    const instantUploadPreview = (file, destID) => {

        let output = document.getElementById(destID);

        // if(!Array.isArray(file)){ 
            // typeof file === 'object'
        if(file.type == undefined){
            output.src = file;
        

        }else{

            output.src = URL.createObjectURL(file);
            output.onload = function() {
                  URL.revokeObjectURL(output.src) // free memory
                }

        }

    };


    const uploadAvatar = async (file) => {

        // instantUploadPreview(file, 'avatarPlace');
        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )
  
    }


    const openTradeModal = () => {
        setWhichModal(3)
        setOpenModal(true)
    }


    const tradeNow = () => {

      if(Number(finance?.[0]?.balance) < Number(finance?.[0]?.minimumParticipation)){
            notify('Balance does not measure up to minimum trade', 0);
            return;
      }

      if(isNaN(tradeAmount) || (tradeAmount <= 0)){
          return;
      }

      setloading(true);
      setLoadingText('Logging trade...');

      dispatch(tradeActions.onPOSTTheTrades({amount:tradeAmount, currency:selectedPlan?.type}, "trade/new", response => {
                setloading(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    setloading(false);
                    setOpenModal(false)
                    // setTradeResultResponse({...response?.data?.event ?? {}})
                    history.push('/arbitrage')
                }
                
            }),
        )

    }

    let totalFreq = 24/selectedPlan?.hoursProfitComesBack


    const pinValidator = () => {
        return <div>
            {/*<div className="alert alert-info">Validate action with account PIN below</div>*/}
            <div class="form-group">
            <span className="text-info-1">Pin</span>
                <input maxLength={6} autoComplete="new-password" placeholder="Account Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
        </div></div>
    }

    const saveBTNJSX = () => (
        <>
            <button type="submit" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
            Submit </button>

            

            
        </>
        )




    const onSuccessOfManualFiatAutoPay = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        loadAllHomePageData();
    };

    // you can call this function anything
    const onCloseOfManualFiatAutoPay = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        notify('You closed payment portal', 0);
    }



    
    
    const name = userData?.username;
    const phone = userData?.phone;
    const email = userData?.email;


    // paystack

     const amount    = getPendingPayment?.[0]?.amountEnforcedToPay * 100;
    const publicKey = site_settings?.site_settings?.paystackPublicKey;
    const reference = getPendingPayment?.[0]?.txn_id;

    const componentProps = {
        email,
        amount,
        reference,
        metadata: {
          name,
          phone,
        },
        publicKey,
        text: "Pay Now"

    }

    const initializePayment = usePaystackPayment(componentProps);


    const payFiatWithPaystack = () => {
        initializePayment(onSuccessOfManualFiatAutoPay, onCloseOfManualFiatAutoPay);
    }

    // pay with flutterwave

    const config = {
        public_key: site_settings?.site_settings?.flutterwavePublicKey,
        tx_ref: getPendingPayment?.[0]?.txn_id,
        amount: getPendingPayment?.[0]?.amountEnforcedToPay,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email,
          phonenumber: phone,
          name,
        },
        customizations: {
          title: 'my Payment Title',
          description: 'Payment for items in cart',
          logo: site_settings?.site_settings?.logo,
        },
    };

    const handleFlutterPayment = useFlutterwave(config);


    const payWithFlutterWave = () => {
        handleFlutterPayment({
            callback: (response) => {
               onSuccessOfManualFiatAutoPay()
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => { onCloseOfManualFiatAutoPay(); },
        });
    }



    const selectFiatAutoPay = () => {
        if(site_settings?.site_settings?.fiatAutomatedDepositMerchant == 0){ // paystack
            payFiatWithPaystack();
        }else if(site_settings?.site_settings?.fiatAutomatedDepositMerchant == 1){ // flutterwave
            payWithFlutterWave();
        }
    }


    const startCalculator = () => {
        setWhichModal(4)
        setOpenModal(true)

    }


    const updateCalculatorInput = (inValue) => {
        let newInput;

        if(calculatorInput == 0){
            newInput = inValue
        }else{
            newInput = String(calculatorInput) + String(inValue)
        }
        setCalculatorInput(newInput)
    }

    const calculateNow = () => {
        let roi = Number(selectedPlanCurrencyForCalculator?.[0]?.roi)
        let round = Number(selectedPlanCurrencyForCalculator?.[0]?.totalRound)
        let input = Number(calculatorInput)

        let result = (((roi/100) * input) + input) * round
        setCalculatorResult(result);
    }

    const salaryData = site_settings?.salary_settings;

    return (

        <SiteWide title={"Home"}>
            <Section hideHeader={true} overlayText={loadingText} loading={loading} title={'Home'}>
                


                <div className="container" style={{backgroundColor: otherColors.cotpBlue}}>
                    <div className="" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        
                        <div className="c-4">

                            <WebLink to={'/'} className="logo">
                                <Images height="30px" src={(site_settings?.site_settings?.logo)} alt="logo" />
                            </WebLink>

                            
                        </div>

                        <div className="c-4" style={{marginRight: 10}}>
                    
                            <WebLink onClick={startCalculator} className="header-user" style={{marginRight: 10}}>
                                <RiCalculatorLine color={otherColors.text} size={23}/>
                            </WebLink>

                            {((userData?.emailVerification ?? 100) != 100) && <WebLink onClick={doLogOutNowNow} className="header-user">
                                <RiLogoutBoxRLine color={otherColors.text} size={23}/>
                            </WebLink>}

                            {((userData?.emailVerification ?? 100) == 100) && <WebLink to={'/signin'} className="header-user">
                                <RiLogoutBoxLine color={otherColors.text} size={23}/>
                            </WebLink>}

                               

                        </div>

                    </div>
                </div>




                <div className="container"  style={{background: otherColors.card,}}>
                    <Slider arrows={false} {...settings}>
                        {site_settings?.slider_settings?.map((item, index) => (
                            <div className="home-slider-background">
                                <a target="_blank" href={item?.linkWhenClicked}>
                                    <Images style={{borderRadius: 10}} resizeMode="contain" height="300px" width="100%" source={item?.image} />
                                </a>
                            </div>)
                        )}
                    </Slider>
                
                    <div style={{color: '#fff', borderRadius: 10}} className="mt-40">
                                

                                <div style={{display: 'flex'}}>
                                    <span style={{}}><RiVolumeUpFill color={otherColors.text} size={26} /></span>
                                    <NewsTicker maxRows = {1} duration = {6000} className="text-info-1">
                                        {site_settings?.news?.map((newsItem, newsIndex) => <WebLink className="text-info-1" to="/news">{newsItem?.subject}</WebLink>)}
                                    </NewsTicker>
                                </div>

                                <hr style={{marginBottom: 30, backgroundColor: otherColors.text, border: 'none', height: '0.3px'}}/>

                                <div className="head text-info-1" style={{display: 'flex', marginBottom: 10, justifyContent: 'space-between'}}>
                                    <h4 className="text-info-1">Asset Watch</h4>

                                    {/*<span>{currencySliderArray(balanceref)}</span>*/}

                                </div>


                                <div className="" style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#171e26', padding: '20px 5px'}}>
                                        
                                        {finance?.map((item, index) => <div style={{isplay: 'flex', }}>

                                            <div className="content text-white">
                                                <h2 className="text-white">{money(item?.balance ?? 0, item?.type ?? 0)}</h2>
                                            </div>

                                        </div>)}


                                        {(finance?.length ?? 0) == 0 && <div>

                                            <div className="content text-white">
                                                <h2 className="text-white">0.00 UNIT</h2>
                                            </div>

                                        </div>}


                                </div>
                                
                                <hr />
                                
                    </div>



                    <div style={{color: otherColors.text}} className="mt-40">



                        <div className="row">
                            

                            {dashMenu.map((item, index) => {

                                if((item.id == 8) && (salaryData?.useSalary == 0)){ return false;}
                                if((item.id == 6) && (site_settings?.operational_settings?.useP2P == 0)){ return false;}
                                
                                const Icon = item.icon;

                                return <div className="col-3 text-center" onClick={item?.click} style={{marginBottom: 60}}>
                                <WebLink title={item.name} to={item.to}>
                                    <button className="text-center my-btn-round my-btn-round-1" style={{border: `2px solid ${otherColors.text}`}}>
                                        <Icon style={{marginTop: 'auto'}} color={otherColors.text} size="25"/>
                                    </button>
                                    <h6 className="small-icon-head">{item.name}</h6>
                                </WebLink>

                            </div>})}



                        </div>
                    </div>



                


                </div>










                <div className="container"  style={{background: otherColors.card, marginTop: 30}}>

                    <div style={{color: otherColors.text}} className="mt-40">



                        <div className="row">
                            

                            {dashMenu2.map((item, index) => {

                                const Icon = item.icon;

                                if((item.id == 1) && (site_settings?.operational_settings?.useAgent == 0)){ return false;}
                                if((item.id == 5) && (site_settings?.operational_settings?.useSwap == 0)){ return false;}
                                if((item.id == 3) && (!userData?.allowVerifyForum)){ return false;}
                                if((item.id == 4) && ((site_settings?.site_settings?.useGoogleMap ?? 0) == 0)){ return false;}
                                if((item.id == 6) && ((site_settings?.operational_settings?.useBonus ?? 0) == 0)){ return false;}
                                if((item.id == 7) && ((site_settings?.operational_settings?.useReinvestBonus ?? 0) == 0)){ return false;}

                                if(item.id == 2){
                                    return <div className="col-3 text-center" style={{marginBottom: 60}}>
                                        <a href={site_settings?.site_settings?.facebook} target="_blank" title={item.name}>
                                            <button className="text-center my-btn-round my-btn-round-1" style={{border: `2px solid ${otherColors.text}`}}>
                                                <Icon style={{marginTop: 'auto'}} color={otherColors.text} size="25"/>
                                            </button>
                                            <h6 className="small-icon-head">{item.name}</h6>
                                        </a>
                                    </div>
                                }

                                if(item.id == 10){
                                    return <div className="col-3 text-center" onClick={item?.click} style={{marginBottom: 60}}>
                                    <a title={item.name} href={item.to}>
                                        <button className="text-center my-btn-round my-btn-round-1" style={{border: `2px solid ${otherColors.text}`}}>
                                            <Icon style={{marginTop: 'auto'}} color={otherColors.text} size="25"/>
                                        </button>
                                        <h6 className="small-icon-head">{item.name}</h6>
                                    </a>
                                    </div>
                                }

                                return <div className="col-3 text-center" onClick={item?.click} style={{marginBottom: 60}}>
                                <WebLink title={item.name} to={item.to}>
                                    <button className="text-center my-btn-round my-btn-round-1" style={{border: `2px solid ${otherColors.text}`}}>
                                        <Icon style={{marginTop: 'auto'}} color={otherColors.text} size="25"/>
                                    </button>
                                    <h6 className="small-icon-head">{item.name}</h6>
                                </WebLink>


                            </div>})}



                        </div>
                    </div>

                </div>













                <Modal
                    isOpen={openModal}
                    onRequestClose={() => setOpenModal(false)}
                    contentLabel="New trade"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    shouldCloseOnOverlayClick={whichModal == 4?true:false}
                    shouldCloseOnEsc={whichModal == 4?true:false}
                  >


                

                    <ModalCustomHead hideHead={whichModal == 4 ?true:false} modalTitle={modalTitle[whichModal]} setOpenModal={() => setOpenModal(false)}>



                        {whichModal == 0 && <div className="actent">
                            <form onSubmit = {processWithdrawal} style={{}} action="">
                                


                                {/*<ul className="list-unstyled my-5 text-small text-left">

                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> System Withdrawal charge - {withdrawalChargePercentage}%</li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> You will be paid - {money((withdrawAmount - ((withdrawalChargePercentage/100) * withdrawAmount) < 0?0:withdrawAmount - ((withdrawalChargePercentage/100) * withdrawAmount)).toFixed(2))}</li>
                                    

                                </ul>*/}

                                <div className="container" style={{height: '60vh', display: 'flex', flexDirection: 'column'}}>
                                    <div>

                                        {selectACurrency()}
                                    

                                        <div class="form-group">

                                            <span className="text-info-1">Sum</span>
                                            <input type="text" className="form-contro form-field" onChange = {(e) => setWithdrawAmount(e.target.value)} value={withdrawAmount} />
                                            
                                        </div>

                                        {pinValidator()}

                                    </div>
                                


                                    <div style={{marginTop: 'auto'}}>
                                        {saveBTNJSX()}
                                    </div>

                                </div>

                            </form>
                        </div>}




                        {whichModal == 1 && <div className="actent">
                            {getPendingPayment?.length == 0 && <form onSubmit = {processDeposit} action="">
                                

                               
                                <div className="container" style={{height: '60vh', display: 'flex', flexDirection: 'column'}}>
                                    <div>

                                        {selectACurrency()}
                                    

                                        <div class="form-group">
                                            <span className="text-info-1" style={{backgroundColor: otherColors.card}}>Sum</span>
                                            <input type="text" style={{backgroundColor: otherColors.card}} className="form-contro form-field" onChange = {(e) => setDepositAmount(e.target.value)} value={depositAmount} />
                                        </div>

                                    </div>
                                    <div style={{marginTop: 'auto'}}>
                                        {saveBTNJSX()}
                                    </div>
                                </div>

                            </form>}


                            {getPendingPayment?.map((pendingPayment, pendingIndex) => {

                                let curDetails = finance?.filter((filitem, index) => filitem.type == getPendingPayment?.[0]?.currency)
                                    
                                    return (<div className="ba-bill-pay-inner" style={{backgroundColor: otherColors.cotpBlue, border: 'none', color: otherColors.text}}>
                                        <div className="ba-single-bill-pay">
                                            <div className="thumb">
                                                <Images height={40} source={curDetails?.[0]?.image} alt="img" />
                                            </div>
                                            <div className="details" style={{width: '100%'}}>
                                                <h5 style={{display: 'flex'}}>
                                                    <span className="text-info-1">
                                                        Deposit
                                                    </span>
                                                    <span className="text-info-1" style={{marginLeft: 'auto'}}>
                                                        {money(getPendingPayment?.[0]?.amountEnforcedToPay, getPendingPayment?.[0]?.currency)}
                                                    </span>
                                                </h5>
                                                <p style={{display: 'flex'}}>
                                                    <span className="text-info-1">
                                                        {getPendingPayment?.[0]?.txn_id}

                                                        {" "}

                                                        <CopyToClipboard text={getPendingPayment?.[0]?.txn_id}
                                                        onCopy={() => notify('Transaction ID copied', 1)}>
                                                            <RiFileCopy2Fill className="cursor-pointer" />
                                                        </CopyToClipboard>
                                                    </span>
                                                    <span className="text-info-1" style={{marginLeft: 'auto'}}>
                                                        {moneyStatus()[getPendingPayment?.[0]?.status]}
                                                    </span>
                                                </p>
                                                <p style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span className="text-info-1">
                                                        <CustomAgo time={getPendingPayment?.[0]?.timestamp}/>
                                                    </span>
                                                    <span>

                                                        {getPendingPayment?.[0]?.createdWithAutoOrManual == 0 && getPendingPayment?.[0]?.autoConfirm == 0 && 
                                                            <span style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} className="" onClick={() => selectFiatAutoPay()}> Pay Now </span>} |
                                                    </span>
                                                </p>
                                            </div>
                                            

                                        </div>

                                        {getPendingPayment?.[0]?.status == 0 && 
                                                            <span style={{position: 'absolute', top: 5, right: 20,  textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.confirm('Clicking OK will delete this invoice permanently. Click OK to confirm deletion') && deleteInv(getPendingPayment?.[0]?.txn_id)}> <RiCloseCircleFill size={20} color="#f00" /></span>}
                                            

                                        <div className="amount-inner text-info-1">
                                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 10}}>
                                                <h5 className="text-info-1">Details</h5>
                                            </div>

                                        

                                            <div className="row" style={{}}>
                                                {getPendingPayment?.[0]?.autoConfirm == 1 && <div  className="col-md-6">
                                                    <h6>
                                                        <CopyToClipboard text={getPendingPayment?.[0]?.address}
                                                            onCopy={() => notify('Copied', 1)}>
                                                            <span className="text-info cursor-pointer">
                                                                {getPendingPayment?.[0]?.address} <RiFileCopy2Fill />
                                                            </span>
                                                        </CopyToClipboard>
                                                    </h6>
                                                    <small>Send Only USDT TRC20 to this wallet</small><br/>
                                                    <div style={{backgroundColor: '#fff', display: 'inline-block', marginTop: 20}}>
                                                        <Images style={{fill: "red"}} src={getPendingPayment?.[0]?.qrCode} />
                                                    </div>
                                                </div>}



                                                <div className="col-md-6">
                                                    {getPendingPayment?.[0]?.createdWithAutoOrManual == 1 && <div>

                                                        <div class="form-group">
                                                            <span className="text-info-1">Name of depositor</span>
                                                            <input id="depositorName" type="text" className="form-contro form-field" onChange = {(e) => setDepositorName(e.target.value)} value={depositorName} />
                                                        </div>

                                                        <div class="form-group">
                                                            <span className="text-info-1">Depositor {getPendingPayment?.[0]?.autoConfirm == 0?'Bank':'Wallet'} (source)</span>
                                                            <input id="depositorBank" type="text" className="form-contro form-field" onChange = {(e) => setDepositorBank(e.target.value)} value={depositorBank} />
                                                        </div>


                                                        <div class="form-group">
                                                            <span className="text-info-1">Amount Deposited</span>
                                                            <input id="depositorAmountPaid" type="text" className="form-contro form-field" onChange = {(e) => setDepositorAmountPaid(e.target.value)} value={depositorAmountPaid} />
                                                        </div>


                                                        <div class="form-group">
                                                            <span className="text-info-1">Image File</span>
                                                            <span>
                                                                <span onClick={() => progClickPaymentUpload()}  style={{textDecoration: 'underline', cursor: 'pointer'}}>
                                                                    <FcUpload />{(getPendingPayment?.[0]?.image == null)?'Upload':'Re-upload'} Payment
                                                                </span>
                                                                <input style={{display: 'none'}} ref={uploadPayment} onChange={(e) => setDepositorFile(e.target.files[0])} type="file" accept=".jpg, .gif, .png, .jpeg"/>
                                                            </span>
                                                            <span className="text-info-1">
                                                                <Images style={{height: 70}} source={(depositorFile == '')?'assets/img/image.png':depositorFile} />
                                                            </span>
                                                        </div>

                                                        <div class="form-group">
                                                            <button onClick = {uploadSelectedPayment} type="submit" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
                                                                Submit
                                                            </button>
                                                        </div>

                                                        
                                                    </div>}
                                                </div>



                                                
                                            </div>


                                            {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <h6 className="text-info-1">
                                                    {curDetails?.[0]?.accountNameText}
                                                </h6>

                                                <h6>
                                                    <span className="text-info cursor-pointer">
                                                        {curDetails?.[0]?.accountName}
                                                    </span>
                                                </h6>

                                                
                                            </div>}

                                            {getPendingPayment?.[0]?.autoConfirm == 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <h6 className="text-info-1">
                                                    {curDetails?.[0]?.accountNumberText}
                                                </h6>

                                                <h6 className="text-info-1">
                                                    <CopyToClipboard text={curDetails?.[0]?.accountNumber}
                                                        onCopy={() => notify('Copied', 1)}>
                                                        <span className="text-info cursor-pointer">
                                                            {curDetails?.[0]?.accountNumber}
                                                            <RiFileCopy2Fill />
                                                        </span>
                                                    </CopyToClipboard> | <span className="text-info cursor-pointer">{curDetails?.[0]?.bankName}</span>
                                                </h6>

                                                
                                            </div>}



                                        </div>
                                    </div>)
                                })}



                        </div>}




                        {whichModal == 2 && <div className="actent">
                            <form onSubmit = {processTransfer} action="">
                                
                                <div className="container" style={{height: '60vh', display: 'flex', flexDirection: 'column'}}>
                                    <div>

                                        {selectACurrency()}

                                        <div class="form-group">
                                            <span className="text-info-1">Sum</span>
                                            <input type="text" className="form-contro form-field" onChange = {(e) => setSendingAmount(e.target.value)} value={sendingAmount} />
                                        </div>


                                        <div class="form-group">
                                            <span className="text-info-1">3rd Party username</span>
                                            <input type="text" className="form-contro form-field" onChange = {(e) => setReceiverEmail(e.target.value)} value={receiverEmail} />
                                        </div>

                                
                                        {pinValidator()}

                                    </div>

                                    <div style={{marginTop: 'auto'}}>
                                        {saveBTNJSX()}
                                    </div>

                                </div>

                            </form>
                        </div>}



            
                        {whichModal == 3 && <div className="actent">
                            <form onSubmit = {() => tradeNow()} action="">
                                


                                <ul className="list-unstyled my-5 text-small text-left">

                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {((Number(selectedPlan?.roi) - 100) * totalFreq).toFixed(1)}%<span className="text-small font-weight-normal ml-2"> daily</span></li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {money(selectedPlan?.minimumParticipation, selectedPlan?.type)} minimum trade</li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {money(selectedPlan?.maximumParticipation, selectedPlan?.type)} maximum trade</li>
                                  <li className="mb-3"><i className="fa fa-check mr-2 text-info"></i> {totalFreq} rounds of Trading</li>
                                  
                                </ul>


                                <div className="form-group basic">
                                    <label className="label">Enter Amount</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="input1">$</span>
                                        </div>
                                        <input type="text" className="form-contro form-field" onChange = {(e) => setTradeAmount(e.target.value)} value={tradeAmount} />
                                    </div>
                                </div>


                                     

                                <div className="form-group basic">
                                    <button className="text-white texttext cursor-pointer">Submit <i className="fa fa-long-arrow-right"></i></button>
                                </div>
                            </form>
                        </div>}



                        {whichModal == 4 && <div className="actent">

                            <div class="calcu-container">
                                <div class="calc-body">
                                    <div class="calc-screen">
                                        <div  class="calc-operation" style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <Menu menuButton={<MenuButton style={{marginLeft: 10}}><RiMore2Fill color={"#fff"} /></MenuButton>} transition>
                                                    {selectedPlanCurrencyForCalculator?.map((menuItem, menuIndex) => <MenuItem><small>{menuItem?.name}</small></MenuItem>)}
                                                    <MenuItem onClick={() => setOpenModal(false)}><small>Close</small></MenuItem>
                                                </Menu>
                                            </div>
                                            <div>{calculatorInput}</div>
                                        </div>
                                        <div class="calc-typed">{calculatorResult}</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button c" onClick={() => {setCalculatorInput(0); setCalculatorResult(0)}}>C</div>
                                        {site_settings?.plans?.map((item, index) => <div onClick={() => {setSelectedMotherCurrencyForCalculator(item.mother); setSelectedPlanCurrencyForCalculator(item?.childs)}} className={`button  l ${selectedMotherCurrencyForCalculator.type == item.mother.type?'bg-warning text-white':''}`}>{item.mother.name}</div>)}

                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput(7)}>7</div>
                                        <div class="button" onClick={() => updateCalculatorInput(8)}>8</div>
                                        <div class="button" onClick={() => updateCalculatorInput(9)}>9</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput(4)}>4</div>
                                        <div class="button" onClick={() => updateCalculatorInput(5)}>5</div>
                                        <div class="button" onClick={() => updateCalculatorInput(6)}>6</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput(1)}>1</div>
                                        <div class="button" onClick={() => updateCalculatorInput(2)}>2</div>
                                        <div class="button" onClick={() => updateCalculatorInput(3)}>3</div>
                                    </div>
                                    <div class="calc-button-row">
                                        <div class="button" onClick={() => updateCalculatorInput('.')}>.</div>
                                        <div class="button" onClick={() => updateCalculatorInput(0)}>0</div>
                                        <div class="button" onClick={() => calculateNow()}>=</div>
                                    </div>
                                </div>
                            </div>


                        </div>}




                    </ModalCustomHead>


                    

                </Modal>




            </Section>

        </SiteWide>
    );
}

export default Home;