import React, {useEffect} from "react";
import LoadingOverlay from 'react-loading-overlay';
// import BounceLoader from 'react-spinners/BounceLoader'
import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';

import { allRemoteSettingsSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import Notify from './Notify';
import Navbar from "../layouts/Navbar";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Images} from "./index";


function SiteWide(props) {

    const {title, description, children, ...rest} = props
    const site_settings = useSelector(allRemoteSettingsSelect);


    const titleToUse = title ?? site_settings?.site_settings?.title ?? 'welcome';
    const descToUse = description ?? site_settings?.site_settings?.description ?? 'You are here';
    const siteName = site_settings?.site_settings?.sitename ?? 'dotcom';
    const url = site_settings?.site_settings?.site_url ?? 'dotcom';
    const logo = site_settings?.site_settings?.logo ?? '';
    const favicon = site_settings?.site_settings?.favicon ?? '';

    const bg = require('../../images/auth.jpg');

    return (
        <HelmetProvider>
            <LoadingOverlay
                active={site_settings?.masterIsLoading ?? true}
                spinner={<Images src="assets/img/loading.gif" />}
            >
                <Helmet>

                    <meta name="theme-color" content={header} />

                    <title>{titleToUse}</title>
                    <link rel="icon" type="image/x-icon" href={favicon} />
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                    <meta name="description" content={descToUse} />

                    <meta property="og:title" content={titleToUse} />
                    <meta property="twitter:title" content={titleToUse} />
          
                    <meta property="og:site_name" content={siteName} />
                    <meta property="twitter:site" content={siteName} />

                    <meta property="og:description" content={descToUse} />
                    <meta property="twitter:description" content={descToUse} />
          
                    <meta property="og:type" content="website" />

                    <meta property="og:url" content={url} />

                    <meta property="og:image" content={logo} />
                    <meta property="twitter:image" content={logo} />

                    <meta name="twitter:card" content="summary_large_image" />

                </Helmet>

                {/*<div {...rest} style={{backgroundImage: "url(" + (props.backgroundImage ?? '') + " )", backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundColor: '#fff'}} >*/}
                <div {...rest}   style={{backgroundColor: otherColors.body}} >
                    <Notify/>
                    <div id={!props.hideMainDiv?'main':''}>
                        {children}
                    </div>
                </div>

            </LoadingOverlay>
        </HelmetProvider>
    );
}

export default SiteWide;