import React, {Component} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import AutoNotification from "./AutoNotification";
import {Images} from "../customComponents";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

function Section(props){

    return (

        <section className="main-section" style={props.sectionstyle}>
            {!props.hideHeader && <Header back={props.back} title={props.title} rightTopMenu={props.rightTopMenu} home={props.home} searchPopup={props.searchPopup} />}
            {props.children}
            <br />
            <Footer />
            {props.loading && <LoadingOverlay
                active={props.loading}
                spinner={<div className="text-center"><Images height="120" src="assets/img/loading.gif" /></div>}
                text={(props.overlayText ?? '' !='') ?props.overlayText: 'Requesting...'}
                styles={{


                        overlay: (base) => ({
                          ...base,
                          background: 'rgba(0, 0, 0, 0.9)',
                          borderRadius: 15,
                          color: '#fff',
                        }),

                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            color: '#fff',
                            '& svg circle': {
                                    stroke: 'rgba(0, 0, 0, 0.9)'
                                }
                        }),
                        wrapper: {
                            width: '100%',
                            height: '100vh',
                            position: props.loading?'fixed':'none',
                            zIndex: '999999999999999999999999',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#fff'
                        }
                    }}
            >
            </LoadingOverlay>}}
        </section>
    );

}

export default Section;