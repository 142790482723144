import React, {useState, useEffect} from "react";
import {WebLink, SiteWide, Images} from "./";
import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';

import { RiCheckboxIndeterminateLine, RiCheckboxLine, RiArrowLeftLine, RiShareForward2Fill, RiBattery2ChargeLine, RiBatteryShareFill, RiCastLine, RiDownload2Line, RiNotification3Line, RiArrowDownCircleFill, RiMailCheckFill, RiErrorWarningFill, RiDeleteBin2Fill, RiMoneyDollarCircleLine, RiFileCopy2Fill, RiGiftLine, RiSecurePaymentLine, RiRecordCircleFill, RiShieldCrossLine, RiUser4Fill, RiExternalLinkFill, RiContactsBookFill,

RiWallet3Fill, RiCalendarTodoFill, RiArrowGoBackLine, RiGasStationFill, RiBankCard2Line, RiShieldUserFill, RiTeamFill, RiTicket2Line, RiUserAddFill } from "react-icons/ri";



export default function ModalCustomHead(props){
	
	return (<div className="modal-content" style={{height: '100vh', backgroundColor:  otherColors.body}}>

        {!props.hideHead && <><div className="header-area"  style={{backgroundColor: otherColors.card,  zIndex: '9999999', marginTop: 30, position: 'absolute'}}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-4 col-4">
                        <WebLink className="menu-back-page home-clicked"  onClick={() => props.setOpenModal(false)}>
                            <RiArrowLeftLine size={18} />
                        </WebLink>
                    </div>
                    <div className="col-sm-4 col-4 text-center">
                        <div className="page-name">{props.modalTitle}</div>
                    </div>
                </div>
            </div>
        </div></>}


        <div className="modal-body" style={{overflowY: 'auto', marginTop: !props.hideHead?'150px':'20px', }}>

        	{props.children}


        </div>
        
    </div>)
}