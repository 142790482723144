import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {show, hide} from "../components/customComponents/Notify";
import {mainStyle, mainColor, header, footer, otherColors} from './styles';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";



class Helper1{


  status = () => {
    return {0:'Awaiting approval', 1:'Active', 2:'Suspended'};
  }

}


export const notify = (msg, code) => {
    show(msg, code)
  
}


export const niceDate = (paramObj) => {
  const dateObj = new Date(paramObj);
  return dateObj.toDateString() + ' ' + dateObj.getHours()+ ':' + dateObj.getMinutes();
}

export const money = (amount, currency, naira = false) => {
  var nf = new Intl.NumberFormat();
  // return (naira?'NGN':'') + (nf.format(amount)) + (!naira?'USDT':'')
  let syn = (currency==170 || currency == 171)?' USDT':' NGN';
  return (nf.format(amount)) + syn
}


export const FormatOnly = (amount) => {
  var nf = new Intl.NumberFormat();
  return (nf.format(amount))
}



export const customModalStyles = {
      content: {
            position: 'absolute',
            bottom: 0,
            border: '0px solid #ccc',
            background: footer,
            borderRadius: '30px 30px 0 0',
            outline: 'none',
            padding: '20px',
            color: '#fff',
            marginBottom: 0,
            width: '100%',
            transition: '0.5s',
      },
      overlay: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999999,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        padding: 0,
        margin: 0,
      }
    };



export const customNormalModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '100%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    zIndex: 999999,
    
  },

  overlay: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999999,
        backgroundColor: otherColors.cotpBlue,
        padding: 0,
        margin: 0,
      }
};




export const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };




export const currencySliderArray = (refToUse, financeArray) => {
    let arrowd;

    arrowd = financeArray?.length > 1 && <div>
        <BsArrowLeftSquare onClick={() => refToUse.current.slickPrev()} className="cursor-pointer" size={20}/> {" "}
        <BsArrowRightSquare onClick={() => refToUse.current.slickNext()} className="cursor-pointer" size={20}/>
    </div>

    return arrowd;
}


  export const moneyStatus = () => {
    return ['Pending', 'Processing', 'Confirmed', 'Cancelled'];
  }


export const usefulColors = (code) => {
    let array = ['danger', 'success', 'info', 'warning'];
    return array[code] ?? 'warning';
}


export default Helper1;