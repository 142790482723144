import React, {Component} from "react";
import Colors from '../../utility/Colors';
import {mainStyle, mainColor, header, footer} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';

import {WebLink, SiteWide, Images} from "../customComponents";

class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-area">
                    { 1>2 &&
                    <div className="footer-top text-center" style={{backgroundImage: "url(assets/img/bg/7.png)"}}>
                        <div className="container">
                            <p>Copyright © Bankapp 2020. All Rights Reserved.</p>
                        </div>
                    </div>
                    }
                    <div className="conainer" id="floatFootMenu">
                        <div className="footer-bottom" 
                            style={{background: footer}}
                        >
                            <ul style={{display: 'flex', color: '#cecece'}}>
                                
                                {menuItems.map((item, index) => {
                                    const MenuIcon = item.icon
                                    return <li className="each-bottom-menu">
                                    <WebLink className="home-clicked text-white" to={item.address}>
                                        <MenuIcon size={35} />
                                        <p>{item.title}</p>
                                    </WebLink>
                                </li>})}

                            </ul>
                        </div>
                    </div>
                </div>

                {/* Back to Top Area */}
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
            </>
        );
    }
}

export default Footer;