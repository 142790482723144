import React, {Component, useEffect} from "react";
import Section from "./layouts/Section";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {WebLink, SiteWide} from "./customComponents";

function Faq(){

    const bakground = require('../images/space.jpg');

    const site_settings = useSelector(allRemoteSettingsSelect);

    return (
        <SiteWide id="main" ackgroundImage={bakground} title={`FAQ`}>
            <Section hideHeader={true} title={'FAQ'}>
                <div className="container-inner mg-top-30">
                    <div className="container-fluid">
                        <div className="row flex-row">

                        
                            <div className="col-xl-12">
                                {/* Basic Tabs */}

                                {site_settings?.faq?.map((item, index) => (
                                <div className="widget has-shadow" style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
                                    <div style={{borderBottom: '1px solid #cecece', backgroundColor: 'rgba(255, 255, 255, 0.8)'}} className="bg-ino widget-header bordered no-actions d-flex align-items-center">
                                        <h6 className="text-dark">{item?.question}</h6>
                                    </div>
                                    <div className="widget-body sliding-tabs">
                                        
                                        <div className="tab-content pt-3">
                                            <div className="text-dark tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="base-tab-1">
                                                {item?.answer}
                                            </div>
                                        </div>
                                    </div>

                                </div>)
                                )}
                            </div>

                        </div>
                        {/* End Row */}
                    </div>
                </div>
            </Section>
        </SiteWide>
    );

}

export default Faq;