import React, {useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {Link} from "react-router-dom";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import ReactTimeAgo from 'react-time-ago'

const tradeBTN = require('../images/trade_button_bg.jpg');

function Home() {

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);

    const [amount, setAmount]   = useState('');
    const [currency, setCurrency]   = useState(finance?.[0]?.country);


    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('Loading trade history...');
    const [openTradeModal, setOpenTradeModal]   = useState(false);
    const [openSuccessModal, setOpenSuccessTradeModal]   = useState(false);

    const dispatch = useDispatch();




    useEffect(() => {
        dispatch(tradeActions.onGETTheTrades("trade/all", response => {}),
        )
    }, [])


    const useAllBalance = () => {
        setAmount(finance?.[0]?.balance)
    }

    return (
        <SiteWide title={"History"}>
            <Section overlayText={loadingText} loading={loading} title={'Trades'}>
            




                {/* transaction start */}
                <div className="transaction-area pd-top-40">
                    <div className="container">
                        
                        <div>
                            

                            {tradesData?.data?.map((item, index) => <div style={{backgroundColor: '#4158D0',
backgroundImage: 'linear-gradient(43deg, #4158D0 0%, #C850C0 66%)', justifyContent: 'center', alignItems: 'center', border: 0, display: 'flex', flex: 5, padding: 10, marginTop: 10}} className="bation">
                                
                                <Images height={60} src={require('../images/chronometer.png')} />

                                <div style={{flex: 3, padding: 15}}>
                                    <h6 className="text-white">{item.txn_Id}</h6>
                                    <p style={{color: '#fff0b9'}}>
                                        <ReactTimeAgo date={item.timestamp} locale="en-US"/>
                                    </p>
                                </div>
                                <div style={{flex: 1, padding: 10}} className="text-right">
                                    <small className="amont text-white">
                                        <Countdown
                                            zeroPadTime={2}
                                            key={new Date(item?.addProfitBy)}
                                            date={new Date(item?.addProfitBy)}
                                            renderer={({ hours, minutes, seconds, completed }) => {
                                              if (completed) {
                                                return <span>{(item.profit == 0) ?'Computing...': 'Profit:' + money(item.profit, item.type)}</span>
                                              } else {
                                                return <span>{hours}:{minutes}:{seconds}</span>;
                                              }
                                            }}
                                          />
                                    </small>
                                    <span style={{display: 'block'}} className="amount">{money(item.capital, item.type)}</span>
                                    <small className="amount">{item.merchant}</small>
                                </div>


                            </div>)}


                        </div>
                    </div>
                </div>
                {/* transaction End */}


            </Section>
        </SiteWide>
    );

}

export default Home;