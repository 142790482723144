import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';

import {Images} from "./customComponents";
import parse from 'html-react-parser';

    

function News(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    return (
        <SiteWide id="main" title={"News"}>
            <Section  title={'News'}>
                {/* transaction start */}
                <div className="container">
                    <div className="" style={{marginTop: 100}}>

                        {site_settings?.news?.map((item, index) => <div className="abut-content-inner" style={{padding: 20, marginBottom: 30, backgroundColor: otherColors.cotpBlue}}>
                            <h5 className="text-info-1">{item.subject}</h5>
                            <hr />
                            <p className="text-info-1">{parse(item.message)}</p>
                        </div>)}
                        
                        
                    </div>
                </div>
                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default News;