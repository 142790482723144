import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankCardFill, RiArrowRightCircleFill, RiFileCopyFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0

import { FcSurvey, FcTwoSmartphones, FcDiploma1, FcCurrencyExchange, FcKey, FcLock } from "react-icons/fc";


function UserSetting(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [withdrawalPin2, setWithdrawalPin2]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [email, setEmail]   = useState(userData?.email ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');
    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');

    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);

    const doPasswordChange = async (e) => {

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/change_password", {password, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setPassword('');
                setWithdrawalPin('');
            }
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }






    const doAddressChange = (e) => {
        setloading(true);
        e.preventDefault();

        let updateObject;

        if(userData.depositMethod == 1){
            updateObject = {wallet}
        }else{
            updateObject = {bank, accountName, accountNumber, pin: withdrawalPin}
        }

        dispatch(authActions.onLogin(`account/account_change/${financeEditing}`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){
                setWithdrawalPin('')
            }
        }),
        );
    }


    const doWithdrawalPinChange = () => {

        if(withdrawalPin != withdrawalPin2){
            notify('PIN mismatch. Please re-validate PIN.')
            return;
        }
        
        setloading(true)
        setLoadingText('Changing Withdrawal PIN...')

        dispatch(authActions.onLogin(`account/withdrawal-pin/change`, {emailOTP, withdrawalPin}, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){  setWithdrawalPinModal(false); setWithdrawalPin(''); }
        }),
        );

    }

    const getNewEmailOTP = () => {
        // setloading(true)
        // setLoadingText('Sending confirmation')

        // dispatch(authActions.onLogin(`account/send-email-otp/withdrawal-pin`, {}, response => {
        //     // notify(response.msg, response.code)
        //     setloading(false);
            setWithdrawalPinModal(true)
        // }),
        // );

    }


    const popUpAccountModal = async (financeWo) => {
        setFinanceEditing(financeWo)
        setWalletModal(true)
    }


    const popUpPhoneModal = () => {
        setPhoneModal(true)
    }


    const saveBTNJSX = (callback, close) => (
        <>
            <button onClick={callback}  type="button" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
            OK </button>

        </>
        )

    const closeModal = (close) => {
        return false;
        // <div className="text-center mt-40"><WebLink onClick={() => close(false)} className="btn btn- btn-danger cursor-pointer">Close</WebLink></div>
    }

    const doPhoneChange = () => {
        setloading(true);
        setLoadingText('Updating phone...')
        dispatch(authActions.onLogin("account/update_phone", {country, phone, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setWithdrawalPin('')
            }
            notify(response.msg, response.code)
            setloading(false);
        })
        );
    }

    const retrieveDepositBonus = () => {
        setloading(true);
        setLoadingText('Retrieving deposit bonus...')

        dispatch(authActions.onLogin(`withdraw/bonus`, {currency: finance?.[0]?.type}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setloading(false);
        }))

    }


    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }


    const kycStatus = () => {
        let statusText = 0;
        let newArray = userData?.kyc?.map((item, index) => {
            if(item.status == 2){
                statusText = 'Approved';
            }else if(item.status == 1){
                statusText = 'Processing';
            }else if(item.status == 0){
                statusText = 'Pending';
            }
        })

        return statusText;
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }

    React.useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 1000)
        
        return () => clearTimeout(timing);
    }, [])

    const pinValidator = () => {
        return <div>
            <div class="form-group">
            <span className="text-info-1">PIN</span>
                <input maxLength={6} autoComplete="new-password" placeholder="Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
            
        </div></div>
    }

    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };


    const goHome = () => {
        window.location.href = homepage;
    }

    const copyNow = () => {
        let userRef = site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid
        copy(userRef)
        notify("copied Link", 1);
    }

    const dashMenu = [
        {
            icon: FcSurvey,
            name: 'Referral Link',
            color: '#206afc',
            to: () => copyNow(),
            value: userData?.myrefid
        },
        {
            icon: FcTwoSmartphones,
            name: 'Update profile',
            color: '#206afc',
            to: () => history.push('/phone'),
            value: userData?.phone?.substring(0,2) + '****'
        },
        {
            icon: FcDiploma1,
            name: 'Bind Account',
            color: '#9e6af8',
            to: () => history.push('/bank'),
        },
        {
            icon: FcCurrencyExchange,
            name: 'TRC20 Wallet',
            color: '#9e6af8',
            to: () => history.push('/wallet'),
        },
        {
            icon: FcLock,
            name: 'Change password',
            color: '#f70',
            to: () => setPasswordModal(true),
            value: "******"
        },
        {
            icon: FcKey,
            name: 'Update PIN',
            color: '#392',
            to: () => getNewEmailOTP(),
            value: "******"
        },
        // {
        //     icon: RiEarthLine,
        //     name: 'Home',
        //     color: '#ccc23a',
        //     to: () => goHome(),
        // },
        // {
        //     icon: RiStackshareLine,
        //     name: 'About',
        //     color: '#AE95A4',
        //     to: () => history.push('/about'),
        // },

        
        
    ]



    return (
        <SiteWide id="main" title={`Information`}>
            <Section loading={loading} overlayText={loadingText} title={'Information'}>

                <div className="transaction-details">
                    




                    <div className="container" style={{marginTop: 60}}>

                        <div className="row">
                            <div className="col-xlg-12 col-md-12 col-12" style={{marginBottom: 200}}>
                                <div style={{}} className="top-report">


                                        

                                        {dashMenu.map((item, index) => {

                                            const Icon = item.icon;

                                            return <WebLink style={{padding: 20, display: 'flex', justifyContent: 'space-between', marginTop: 20, backgroundColor: otherColors.card, color: '#fff', borderRadius: 10}} title={item.name} onClick={item.to}>

                                                
                                                <div style={{display: 'flex'}}>
                                                    <span><Icon color={otherColors.text} size="24"/> </span>
                                                    <h6 className="text-info-1" style={{marginLeft: 20}}>{item.name}</h6>
                                                </div>

                                                <div style={{display: 'flex'}}>
                                                    <h6 className="text-info-1">{item.value}</h6>
                                                    <span style={{marginLeft: 20}}><RiArrowRightCircleFill  color={otherColors.text} size="21"/> </span>

                                                </div>



                                            </WebLink>})}




                                </div>
                            </div>
                        </div>

                    </div>



                </div>

                

                <Modal
                    isOpen={walletModal}
                    onRequestClose={() => setWalletModal(false)}
                    contentLabel="Example Modal"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    
                  >
                    <div className="modal-content">
                        <div className="modal-body">
                            
                            <div className="modl-body">
                                <form onSubmitFaked={doAddressChange} className="contact-f-box">


                                    {userData.depositMethod == 1 && <div class="form-group">

                                            <span className="text-dark"><RiBankFill size={20} /></span>
                                            <input value={wallet} placeholder="Bank" onChange = {e => setWallet(e.target.value) } required="" className="form-field form-control" type="text" />
                                            <span className="text-dark">USDT TRC20</span>

                                        </div>}

                                    {userData.depositMethod == 0 && <div>
                                        



                                        <div class="form-group">

                                            <span className="text-dark"><RiBankFill size={20} /></span>
                                            <input value={bank} placeholder="Bank" onChange = {e => setBank(e.target.value) } required="" className="form-field form-control" type="text" />
                                            <span className="text-dark">Bank</span>

                                        </div>

                                        <div class="form-group">
                                            <span className="text-dark"><RiAccountBoxFill size={20} /></span>
                                            <input value={accountName} placeholder="Account Name" onChange = {e => setAccountName(e.target.value) } required="" className="form-field form-control" type="text" />
                                            <span className="text-dark">Name</span>
                                        </div>

                                        <div class="form-group">
                                            <span className="text-dark"><RiListOrdered size={20} /></span>
                                            <input value={accountNumber} placeholder="Account Number" onChange = {e => setAccountNumber(e.target.value) } required="" className="form-field form-control" type="text" />
                                            <span className="text-dark">Number</span>
                                        </div>

                                        {pinValidator()}

                                    </div>}
                                    {saveBTNJSX(doAddressChange, setWalletModal)}
                                    
                                </form>
                            </div>

                                

                        </div>

                    </div>

                    {closeModal(setWalletModal)}

                </Modal>




                <Modal
                    isOpen={passwordModal}
                    onRequestClose={() => setPasswordModal(false)}
                    contentLabel="Example Modal"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    
                  >
                    <div>
                        <div className="modal-content" style={{backgroundColor: otherColors.cotpBlue, boxShadow: 'none'}}>
                            <div className="modal-body">
                                <form onSubmitFaked={doPasswordChange} className="cntact-f-box" style={{}}>
                                    <div class="form-group">
                                        <span className="text-info-1">Password</span>
                                        <input autoComplete="new-password" placeholder="New password" onChange = {e => setPassword(e.target.value) } required="" className="form-field form-control" type="password" />
                                        
                                    </div>
                                    
                                    {pinValidator()}

                                    {saveBTNJSX(doPasswordChange, setPasswordModal)} 
                                    
                                </form>
                            </div>
                            
                        </div>
                    </div>

                    {closeModal(setPasswordModal)}

                </Modal>



                <Modal
                    isOpen={phoneModal}
                    onRequestClose={() => setPhoneModal(false)}
                    contentLabel="Phone Modal"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                  >
                    <div>
                        <div className="modal-content bg-warnig">
                            
                            <div className="modal-body" style={{padding: '0 !important'}}>
                                <form onSubmitFaked={doPhoneChange} className="contact-f-box" style={{}}>
                                    
                                    <div class="form-group">
                                        <span className="text-whie"><RiEarthLine  size={20}/></span>
                                        <select style={{height: 45}} value={country} onChange={e => changeCountry(e.target.value) } required=""  className="form-field form-control">
                                            <option hidden value="">Select country</option>
                                            {
                                                staticCountries.map((item, index) => 
                                                    <option key={index} value={item.id}>{item.nicename}</option>
                                                )
                                            }
                                        </select>

                                        <span className="text-whie">Country</span>
                                    </div>


                                    {/*<div class="form-group">
                                        <span className="text-dark">Email</span>
                                        <input type='text' value={email} className="form-field form-control" onChange={e => setPhone(e.target.value) } required placeholder="Phone" />
                                    </div>*/}

                                    <div class="form-group">
                                        <span className="text-dark">{phoneCode}</span>
                                        <input type='text' value={phone} className="form-field form-control" onChange={e => setPhone(e.target.value) } required placeholder="Phone" />
                                        <span className="text-dark">Phone</span>
                                    </div>
                                            

                                    {pinValidator()}
                                    
                                    {saveBTNJSX(doPhoneChange, setPhoneModal)} 
                                </form>
                            </div>
                            
                        </div>
                    </div>
                    {closeModal(setPhoneModal)}
                </Modal>





                <Modal
                    isOpen={withdrawalPinModal}
                    onRequestClose={() => setWithdrawalPinModal(false)}
                    contentLabel="Example Modal"
                    style={customNormalModalStyles}
                    className="add-balance-inner-wrap"
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                  >
                    <div>
                        <div className="modal-content bg-warnig">
                           
                            <div className="modal-body" style={{padding: '0 !important', backgroundColor: otherColors.cotpBlue}}>
                                <form onSubmitFaked={doWithdrawalPinChange} className="contact-f-box" style={{}}>
                                    

                                    <div class="form-group">
                                        <span className="text-info-1">New Pin</span>
                                        <input maxLength={6} autoComplete="new-password" placeholder="New Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="form-field form-control" type="password" />
                                        
                                    </div>



                                    <div class="form-group">
                                        <span className="text-info-1">Confirm New Pin</span>
                                        <input maxLength={6} autoComplete="new-password" placeholder="Confirm New Pin" onChange = {e => setWithdrawalPin2(e.target.value) } required="" className="form-field form-control" type="password" />
                                        
                                    </div>



                                    {/*<div className="form-group">
                                        <span className="text-info-1">OTP</span>
                                        <input type={"text"} className="form-field form-control" onChange={e => setEmailOTP(e.target.value) } required placeholder="OTP" />
                                    </div>*/}

                                    {saveBTNJSX(doWithdrawalPinChange, setWithdrawalPinModal)} 

                                </form>

                                


                            </div>
                            
                        </div>
                    </div>

                    {/*<div className="text-center">
                                        <br />

                        <Countdown
                            zeroPadTime={2}
                            key={new Date(userData?.withdrawalPinResetTime ?? 0)}
                            date={new Date(userData?.withdrawalPinResetTime ?? 0)}
                            renderer={({ hours, minutes, seconds, completed }) => {
                              if (completed) {
                                return <span></span>
                              } else {
                                return <span className="text-white">{minutes}:{seconds} seconds</span>;
                              }
                            }}
                          />
                    </div>*/}


                    {closeModal(setWithdrawalPinModal)}
                    
                    
                </Modal>


            </Section>
        </SiteWide>
    );

}

export default UserSetting;