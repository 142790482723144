import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {useHistory} from 'react-router-dom';
import APIRequests from '../utility/APIRequests';
import Helper1, {notify} from '../utility/Helper1';
import {Alert} from "../alertServices";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {authActions} from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
// import { auth } from '../redux/actions/auth';
import { userSelect } from '../redux/selectors/index';
import '../utility/style/login/auth.css';
import { RiLoginBoxLine, RiHome7Line, RiArchiveLine, RiBarChart2Line, RiBriefcase4Fill, RiArchiveDrawerLine } from "react-icons/ri";


const bacggroundImage = require('../images/home-2.jpg');

function Signin() {

    const dispatch = useDispatch();
    const users = useSelector(userSelect);

    const [email, setEmail]                             = useState();
    const [password, setPassword]                       = useState();
    const [loading, changeLoading]                      = useState(false);
    const [notificationMessage, setNotificationMessage] = useState();
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const [flashCounter, setFlashCounter]               = useState(0);
    const history                                       = useHistory();


    const doLogin = async (e) => {

        e.preventDefault();
        setFullPageLoading(true);
        
        dispatch(authActions.onLogin("account/login", {username:email, password:password}, response => {

            if(response.code != 0){

                if(response.code == 1){
                    history.replace('/personal-information');
                }else if(response.code == 2){
                    history.replace('/verify-email');
                }
            }else{
                notify(response.msg, response.code)
            }

            setFullPageLoading(false);
        
        }),
    );

        
    };

    const bg = require('../images/auth.jpg');

    return (<SiteWide backgroundImage={bg} hideMainDiv= {true} hideMenu={true} title="Sign in">
        <Section hideHeader={true}  loading={fullPageLoading} title={'Sign in'} home={<WebLink to="/signup">Sign up</WebLink>}

        >
            

            <div className="limiter">
        <div className="container-login100">
            <div className="wrap-login100" style={{backgroundColor: otherColors.body}}>
                    <form className="contact-form-inner auth-box login100-form validate-form"  style={{marginTop: 50}}>


                        <label className="single-input-wrap">
                            <span className="text-info-1">Username</span>
                            <input placeholder="Username" onChange = {e => setEmail(e.target.value) } required="" className="auth-input" type="text" />
                        </label>
                        <label className="single-input-wrap">
                            <span className="text-info-1">Password</span>
                            <input placeholder="Password" onChange={e => setPassword(e.target.value) } required="" className="auth-input input100" type="password" />
                        </label>
                        
                        
                        <div className="container-login100-form-btn mt-40">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button  onClick={doLogin} className="login100-form-btn bg-info">
                                    Login
                                </button>
                            </div>
                        </div>


                        <div className="flex-col-c p-t-155">
                            <div style={{textAlign: 'center'}}>
                                <WebLink className="forgot-btn tete text-info-1" to={'/signup'}>Register.</WebLink>
                                {/*<WebLink style={{marginLeft: 'auto'}} className="forgot-btn tte text-info-1" to={'/reset-password'}>Password Reset.</WebLink>*/}
                            </div>
                        </div>

                    </form>


                </div>
            </div>

            </div>
        </Section>
        </SiteWide>
    );

}

export default Signin;