import React, {Component} from "react";
import {WebLink} from "../customComponents";
// import { ToastContainer } from 'react-toastify';
import Colors from '../../utility/Colors';
import {mainStyle, mainColor, header, footer} from '../../utility/styles';
import {useHistory} from "react-router-dom";

function Header(props) {
    
        const history = useHistory();
// console.log(history);
        const loadHome = () => {
            // if(history.goBack.length > 0){
            //     history.goBack() ?? history.push('/')
            // }else{
            //     history.push('/')
            // }

            history.goBack()
        }


        return (
            <>
                {/*<ToastContainer position="bottom-center"/>*/}

                <div className="header-area"  style={{backgroundColor: header}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-4">
                                <WebLink className="menu-back-page home-clicked"  onClick={loadHome}>
                                    <i className="fa fa-angle-left" />
                                </WebLink>
                            </div>
                            <div className="col-sm-4 col-4 text-center">
                                <div className="page-name">{props.title}</div>
                            </div>
                            <div className="col-sm-4 col-4 text-right">
                                <div className="page-name">{props.rightTopMenu}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className="page-title mg-top-50">
                    <div className="container">
                        <WebLink className="float-left home-clicked" to={'/'}>Home</WebLink>
                        <span className="float-right">{props.title}</span>
                    </div>
                </div>
                */}
            </>
        );

}

export default Header;