import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';

    

function About(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    return (
        <SiteWide id="main" title={"Contact us"}>
            <Section title={'Contact us'}>
                {/* transaction start */}
                <div className="transaction-area pd-top-36">
                    <div className="containe text-center" style={{marginTop: 80, padding: 20}}>


                        <div className="text-center">
                            <img height={'160'} src={'assets/img/contact_us.png'} />
                        </div>

                        <br /><br />

                        {site_settings?.site_settings?.contactemail && <div className="about-content-inne">
                            <h3 className="text-white">Contact Email</h3><br />
                            <p className="text-white">{site_settings?.site_settings?.contactemail}</p>
                        </div>}

                        <br />
                        <hr className="horizontal dark my-3" />

                        {site_settings?.site_settings?.contactphone && <div className="about-content-inne">
                            <h3 className="text-white">Contact Phone</h3><br />
                            <p className="text-white">{site_settings?.site_settings?.contactphone}</p>
                        </div>}

                        <br />
                        <hr style={{background: 'linear-gradient(90deg, transparent, red, transparent)'}} />

                        {site_settings?.site_settings?.contactaddress && <div className="about-content-inne">
                            <h3 className="text-white">Contact Address</h3><br />
                            <p className="text-white">{site_settings?.site_settings?.contactaddress}</p>
                        </div>}
                        
                        <br />
                        <br />


                    </div>
                </div>
                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default About;