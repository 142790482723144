
export const mainColor 	= '#232a34'
export const header 	= '#172636'
export const footer 	= '#172636'

// '#262a35'

export const otherColors = {
	card: '#172636',
	body: '#171e26',
	cotpBlue: footer,
	text: '#7f8591',
	btn1: '#d4af37',
}

// #2a7ef2



export const mainStyle = {
	mainBackground: {
		backgroundColor: '#041f4b', 
		padding: 20, 
		borderRadius: 10, 
		color: mainColor
	},
	color_1:{
		color: mainColor
	}
}