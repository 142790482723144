import { RiLoginBoxLine, RiHome6Line, RiGlobalLine, RiBarChart2Line, RiUser2Fill, RiSteamFill } from "react-icons/ri";



export const menuItems = [
    {address: '/', title: 'Home', icon: RiHome6Line},
    {address: '/global', title: 'Go Global', icon: RiGlobalLine},

    {address: '/team', title: 'Earn Interest', icon: RiSteamFill},
    {address: '/personal-information', title: 'Mine', icon: RiUser2Fill},
]

