import React, {useState, useEffect} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {notify, styles, money, customModalStyles} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import ReactTimeAgo from 'react-time-ago'
import Slider from "react-slick";
import { useHistory } from "react-router-dom";


function Bot() {

    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);

    const [openModal, setOpenModal]   = useState(false);
    const [tradeAmount, setTradeAmount]   = useState('');

    const [loading, setloading]   = useState(false);
    const [loadingText, setLoadingText]   = useState('');
    const [selectedPlan, setSelectedPlan]   = useState({});
    const [tradeResultResponse, setTradeResultResponse]   = useState();

    const dispatch = useDispatch();

    const buyABot = (botId) => {
      setloading(true);
      setLoadingText('Preparing purchase');

      dispatch(authActions.onRefreshSystemData(`bot/new/${botId}`, 100, {}, response => {
            setloading(false)
            notify(response.msg, response.code);    
        }))

    }
    

    return (
        <SiteWide title={"Buy a Bot"}>
            <Section overlayText={loadingText} loading={loading} title={'Buy a Bot'}>

              <div className="container p5" style={{padding: 25}}>

                <div className="row">

                  {site_settings?.bots?.map((item, index) => {

                    const existedRobot = userData?.robot?.filter((existeItem, index) => item.id == existeItem.tradingBotId)

                    return <div className={`bot_container bot_container${index+1} col-sm-4 col-6`}>
                      <div className="inner_bot_container">
                          <div className="title">{item.name}</div>

                          <div className="text-center text-white">Trades between <br /> ${item.minimum} - ${item.maximum}</div><br />

                          <div className="main_number"><sup><small style={{fontSize: 26}}>$</small></sup>{item.price}</div>
                          <div className="bot_container_text">
                              <div className="bot_container_text2"></div>
                          </div>
                      </div>
                      <WebLink onClick={() => buyABot(item.id)}><span>{existedRobot.length > 0?<span>Active <i className="fa fa-check"></i></span>:'Continue'}</span></WebLink>
                  </div>})}
                  

                </div>
              </div>


            </Section>
        </SiteWide>
    );

}

export default Bot;