import React, {useState} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';


function Bank({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');
    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');

    const dispatch = useDispatch();

    


    const doAddressChange = (e) => {
        setloading(true);
        e.preventDefault();

        let updateObject;

        // if(userData.depositMethod == 1){
        //     updateObject = {wallet}
        // }else{
            updateObject = {bank, accountName, accountNumber, pin: withdrawalPin}
        // }

        dispatch(authActions.onLogin(`account/account_change/0`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){
                setWithdrawalPin('')
            }
        }),
        );
    }


    return (
        <SiteWide id="main" title={`Bind card`}>
            <Section loading={loading} overlayText={loadingText} title={'Bind card'}>
                

                <div className="container">

                    <form style={{marginTop: 80}} onSubmit={doAddressChange} className="contact-f-box">


                        {userData.depositMethod == 1 && 1>1 && <div class="form-group">

                                <span className="text-dark"><RiBankFill size={20} /></span>
                                <input value={wallet} placeholder="Bank" onChange = {e => setWallet(e.target.value) } required="" className="form-field form-control" type="text" />
                                <span className="text-dark">USDT TRC20</span>

                            </div>}



                        <div>
                            


                            <div className="ba-cart-inner mb-3" style={{backgroundImage: "url(assets/img/bg/4.png)", paddingTop: 50, paddingBottom: 50}}>
                                
                                <p>Card Number</p>
                                <h5>{userData.accountNumber?.substring(0,4) + '******'}</h5>
                                
                                <br />

                                <div className="row">
                                    
                                </div>

                                <br/>
                                
                                <h2 className="text-white">{userData.accountName ?? 'Bind Name'}</h2>
                            </div>


                            <div class="form-group">

                                <span className="text-text-1">Bank</span>

                                {/*<input value={bank} placeholder="Bank" onChange = {e => setBank(e.target.value) } required="" className="form-field form-control" type="text" />*/}
                                
                                <select style={{height: 45}} value={bank} onChange={e => setBank(e.target.value) } required=""  className="form-field form-control">
                                    <option hidden value="">Select Provider</option>
                                    {
                                        site_settings?.banks?.map((item, index) => 
                                            <option key={index} value={item.code}>{item.name}</option>
                                        )
                                    }
                                </select>

                            </div>

                            <div class="form-group">
                                <span className="text-text-1">Name</span>
                                <input value={accountName} placeholder="Account Name" onChange = {e => setAccountName(e.target.value) } required="" className="form-field form-control" type="text" />
                            </div>

                            <div class="form-group">
                                <span className="text-text-1">Number</span>
                                <input value={accountNumber} placeholder="Account Number" onChange = {e => setAccountNumber(e.target.value) } required="" className="form-field form-control" type="text" />
                            </div>


                            <div class="form-group">
                                <span className="text-info-1">PIN</span>
                                <input value={withdrawalPin} maxLength={6} autoComplete="new-password" placeholder="Account Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
                            </div>


                            <p>
                                Total card bind left: {userData?.walletUpdateChanceFiat}
                            </p>

                        </div>

                        <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{backgroundColor: otherColors.btn1}}>
                         Bind </button>

                        
                    </form>

                </div>


            </Section>
        </SiteWide>
    );

}

export default Bank;