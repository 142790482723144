import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Helper1, {notify, niceDate, money} from '../utility/Helper1';
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import CustomAgo from "./customComponents/CustomAgo";

import { useSelector, useDispatch } from 'react-redux';
import {authActions, withdrawalActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import {Images} from "./customComponents";

import {RiUser4Line } from "react-icons/ri";



function Referrals() {    
    const [loadingText, setLoadingText]   = useState('Loading netork...');
    const [loading, setLoading]         = useState(false)
    const [dataForLevel1, setLevel1]    = useState([])
    const [dataForLevel2, setLevel2]    = useState([])
    const [dataForLevel3, setLevel3]    = useState([])
    const [mainUplineData, setMainUplineData]    = useState({})

    const [viewingForLevel1, setViewingForLevel1]   = useState(0)
    const [viewingForLevel2, setViewingForLevel2]   = useState(0)
    const [viewingForLevel3, setViewingForLevel3]   = useState(0)
    const [viewingLevel, setViewingLevel]     = useState(1)
    const [refData, setRefData]     = useState([])
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);


    const dispatch = useDispatch();

    const loadDownLines = (myrefid, level) => {
        
        setLoading(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoading(false)

            setRefData(response?.data?.referrals)
            setMainUplineData(response?.data?.upline)

            // if(level == 1){
                // setLevel1(response?.data?.referrals?.level1)
            // }else if(level == 2){
                // setLevel2(response?.data?.referrals?.level2)
            // }else{
                // setLevel3(response?.data?.referrals?.level3)
            // }

            // setLeaderboardData(response?.data?.leaderboard)
        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])



    const processRefWithdrawal = () => {
        

        setLoading(true);
        setLoadingText('Receiving...');

        dispatch(authActions.onLogin(`withdraw/ref`, {currency: finance?.[0]?.type}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setLoading(false);
        }))
    }





    const userData = useSelector(userSelect);


    const affiliatePlace = () => {
        return <div className="col-12">
            <table className="text-center" style={{backgroundColor: '#262a35', border: 'none'}} className={`table triped ordered hover`}>
              <thead style={{border: 'none', backgroundColor: '#000'}}>
                <tr>
                  {/*<th className="text-center" style={{border: 'none', height: 20}}><span className="text-white">#ID</span></th>*/}
                  <th className="text-left" style={{border: 'none', height: 20}}><span className="text-white">Username</span></th>
                  <th className="text-right" style={{border: 'none', height: 20}}><span className="text-white">Signup date</span></th>
                </tr>
              </thead>
              <tbody style={{border: 'none'}}  className="text-white">

                {refData['level'+viewingLevel]?.map((eachdownline, index) => (
                <tr style={{border: 'none'}}>
                    {/*<td className="text-center" style={{border: 'none'}}><span className="cursor-pointer">{eachdownline?.myrefid}</span></td>*/}
                    <td className="text-left" style={{border: 'none'}}><span>{eachdownline?.username}</span></td>
                    <td className="text-right" style={{border: 'none'}}><span>{niceDate(eachdownline?.date)}</span></td>
                </tr>
                ))}
                

              </tbody>
            </table>
        </div>
    }


    return (<SiteWide id="main" title="Affiliate">
        <Section  hideHeader={true} allNotification={false}  overlayText={loadingText} loading={loading} searchPopup={true} title={'Affiliate'}
        
        rightTopMenu={<CopyToClipboard text={site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid} onCopy={() => notify('Copied', 1)}><span className="cursor-pointer">Ref. code</span></CopyToClipboard>}
        
        >
            <div className="container">
               

                <div className="container" style={{marginTop: 30, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                    


                    <div className="" style={{display: 'flex', justifyContent: 'space-between', backgroundColor: '#171e26', padding: '20px 5px'}}>
                            
                            {finance?.map((item, index) => <div style={{isplay: 'flex', }}>

                                <div className="content text-white">
                                    <h2 className="text-white">{money(item?.commission ?? 0, item?.type ?? 0)}</h2>
                                </div>

                            </div>)}


                            {(finance?.length ?? 0) == 0 && <div>

                                <div className="content text-white">
                                    <h2 className="text-white">0.00 UNIT</h2>
                                </div>

                            </div>}


                    </div>



                    <br /><br />

                    <button onClick={() => processRefWithdrawal()} style={{backgroundColor: '#fff'}}  className={`btn-block b-white btn cursor-pointer`}>
                        Claim
                    </button>


                </div>



                <div className="container" style={{marginTop: 30, backgroundColor: otherColors.cotpBlue, borderRadius: 10}}>
                    
                    <div className="row">
                        
                        <div className="col-6 text-white">Upline</div>
                        <div className="col-6 text-white text-right"><RiUser4Line size={14}/> {mainUplineData?.username}</div>

                    </div>

                </div>




                <div className="container" style={{marginTop: 30, padding: 0}}>
                    <div className="row">
                        {[1,2,3, 4].map((item, index) => <div className="col-3 text-center">
                                <button onClick={() => setViewingLevel(item)} style={{backgroundColor: viewingLevel == item?otherColors.cotpBlue:'transparent'}} className={`text-dark-1 btn-block levelBTN`}>LV{item}</button>
                            </div>)}
                    </div>
                </div>





                <div className="container" style={{marginTop: 30, padding: 0}}>
                    <div className="row">
                        
                        {affiliatePlace()}
                        
                            
                    </div>
                </div>

                        
                    
            </div>
        </Section>
        </SiteWide>
    );

}

export default Referrals;