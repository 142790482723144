import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";
import parse from 'html-react-parser';

    

function About(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    return (
        <SiteWide id="main" title={"About us"}>
            <Section title={'About us'}>
                {/* transaction start */}
                <div className="transaction-area pd-top-36">
                    <div className="containe" style={{marginTop: 80, padding: 20}}>

                        
                        <div className="about-content-inne">
                            <h3 className="text-white">{parse(site_settings?.content_settings?.[0]?.con1)}</h3><br />
                            <p className="text-white">{parse(site_settings?.content_settings?.[0]?.con2)}</p>
                        </div>
                        <br /><br />
                        <div className="about-content-inner p-0">
                            <Images className="w-100" src={site_settings?.image_settings?.[0]?.image1} alt="About us imagery" />
                        </div>

                    </div>
                </div>
                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default About;