import React, {useState, useEffect} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles} from '../utility/Helper1';
import {homepage} from '../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {staticCountries} from '../utility/countries';
import {Images} from "./customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../utility/styles';


function Bank({history}){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');
    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');

    const dispatch = useDispatch();

    


    const doPhoneChange = () => {
        setloading(true);
        setLoadingText('Updating phone...')
        dispatch(authActions.onLogin("account/update_phone", {country, phone, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setWithdrawalPin('')
            }
            notify(response.msg, response.code)
            setloading(false);
        })
        );
    }


    useEffect(() => {
        changeCountry(userData?.country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }


    return (
        <SiteWide id="main" title={`Update phone`}>
            <Section loading={loading} overlayText={loadingText} title={'Update phone'}>
                

                <div className="container">


                    <form onSubmit={doPhoneChange} className="contact-f-box" style={{marginTop: 80, height: '70vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        
                        <div>

                            <div class="form-group">
                                <span className="text-whie"><RiEarthLine  size={20}/></span>
                                <select style={{height: 45}} value={country} onChange={e => changeCountry(e.target.value) } required=""  className="form-field form-control">
                                    <option hidden value="">Select country</option>
                                    {
                                        staticCountries.map((item, index) => 
                                            <option key={index} value={item.id}>{item.nicename}</option>
                                        )
                                    }
                                </select>

                            </div>


                            <div class="form-group">
                                <span className="text-info-1">{phoneCode}</span>
                                <input type='text' value={phone} className="form-field form-control" onChange={e => setPhone(e.target.value) } required placeholder="Phone" />
                            </div>
                                    

                        

                            <div class="form-group">
                                <span className="text-info-1">PIN</span>
                                <input value={withdrawalPin} maxLength={6} autoComplete="new-password" placeholder="Account Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
                            </div>

                        </div>


                        <button type="submit" disabled={loading} className="btn mt-40 btn-block btn-danger" style={{backgroundColor: otherColors.btn1, marginTop: 120}}>
                         Submit </button>


                    </form>


                </div>


            </Section>
        </SiteWide>
    );

}

export default Bank;