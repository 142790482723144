import React, {useState, useEffect} from "react";
import ReactTimeAgo from 'react-time-ago'


function CustomAgo({time}) {

    return <ReactTimeAgo date={new Date(time)} locale="en-US"/>

}

export default CustomAgo;