import React, {useState, useEffect, useRef} from "react";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {Link} from "react-router-dom";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import Modal from 'react-modal';

import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {niceDate, notify, styles, money, customNormalModalStyles, balanceSettings, currencySliderArray} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react'
import ModalCustomHead from "./customComponents/ModalCustomHead";

import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';



import {RiMore2Fill, RiAddBoxFill, RiCloseLine, RiEye2Line, RiIndeterminateCircleFill, RiExchangeLine, RiBankFill, RiHandCoinFill, RiFundsFill, RiPieChartFill} from "react-icons/ri";


const tradeBTN = require('../images/trade_button_bg.jpg');
const waveBg = require('../images/images.jpeg');
  


function Arbitrage() {


    const chooseCurrency = useRef();

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [loading, setloading]   = useState(false);
    const [tradeDataToShow, setTradeDataToShow]   = useState([]);
    const [loadingText, setLoadingText]   = useState('');
    const [currentSentTradeData, setCurrentSentTradeData]   = useState({});
    const tradesData = useSelector(allTradeSelect);
    const finance = useSelector(homeScreenStatSelect);
    const [selectedPlan, setSelectedPlan]   = useState(site_settings?.plans?.[0]?.childs?.[0]);
    const [openModal, setOpenModal]   = useState(false);

    const [openTradeModal, setOpenTradeModal]   = useState(false);
    const [tradeCapital, setTradeCapital]   = useState('');
    const [selectedImage, setSelectedImage]   = useState('');
    const [selectedCurrency, setSelectedCurrency]   = useState('');


    // const tradeDataLength = 10 + (tradeDataToShow?.length ?? 0);
    const dispatch = useDispatch();

    const arbi = require('../images/arbi.jpg');

    const type              = finance?.[0]?.type
    const allUnprocessed    = tradesData?.childs?.filter((item, index) => item.isProcessed == 0)
    const today             = tradesData?.childs?.filter((item, index) => item.today == 1)
    const active            = tradesData?.data?.filter((item, index) => item.active == 1)
    const inActive          = tradesData?.data?.filter((item, index) => item.active == 0)
    const allUnprocessedSum = allUnprocessed?.reduce((a, b) => Number(a) + Number(b.profit), 0)

    const allProfits = today?.reduce((a, b) => Number(a) + Number(b?.profit), 0);
    const allCapitals = today?.reduce((a, b) => Number(a) + Number(b?.capital), 0);
    



    const dashMenu = [
        {
            icon: RiExchangeLine,
            name: 'Successful transaction',
            figure: (today?.length ?? 0),
        },
        {
            icon: RiHandCoinFill,
            name: 'Income from transactions',
            figure: money(allProfits, type),
        },
        {
            icon: RiFundsFill,
            name: 'Transaction amount',
            figure: money(allCapitals, type),
        },
        {
            icon: RiPieChartFill,
            name: 'Total trades',
            figure: money(allCapitals+allProfits, type),
        },
    ]


    if (openTradeModal) {
      // push to history when modal opens
      window.history.pushState(null, '', window.location.href)
      
      // close modal on 'back'
      window.onpopstate = () => {
        window.onpopstate = () => {}
        window.history.back()
        setOpenTradeModal(false)
      }
    }


    const loadAllTrade = () => {
        dispatch(tradeActions.onGETTheTrades("trade/all", response => {
            })
        )
    }

    const intermittentTrade = () => {

        if(allUnprocessed?.length == 0){ notify('No order currently available to process', 0); return; }

        setloading(true);
        setLoadingText('Processing...')

        dispatch(tradeActions.onGETTheTrades("trade/process/manual", response => {
            setloading(false);
            notify(response.msg, response.code)
            })
        )
        
    }

    const terminateContract = (id) => {

        setloading(true);
        setLoadingText('Terminating contract...')

        dispatch(tradeActions.onGETTheTrades(`trade/close/${id}`, response => {
            setloading(false);
            notify(response.msg, response.code)
            })
        )

    }

    const startTradeModal = (currency) => {

        setSelectedCurrency(currency)
        setOpenTradeModal(true)

    }

    const tradeNow = (thePlanId) => {

      setloading(true);
      setLoadingText('Trading...');

      dispatch(tradeActions.onPOSTTheTrades({currency:thePlanId, amount: tradeCapital}, "trade/new", response => {
                setloading(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    setloading(false);
                    setOpenTradeModal(false)
                    referralData()
                }
                
            }),
        )

    }


    useEffect(() => {

        loadAllTrade();

        const reloadEverytime = setInterval(() => {
            loadAllTrade();
        }, 15000)
        
        return () => clearInterval(reloadEverytime);
    }, [])



    useEffect(() => {
        referralData()
    }, [])


    const referralData = () => {
        // const tradeDataLength = 10 + (tradeDataToShow?.length ?? 0);
        const tradeDataLength = (tradeDataToShow?.length ?? 0);

        const tradesDataCloned = [...tradesData.childs]

        const newLength = tradesDataCloned?.slice(0, (tradeDataLength + 2));

        // setTradeDataToShow((e) => [...e, ...newLength])
        setTradeDataToShow((e) => [...newLength])

    }
 

const balanceSettings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
        }]
    };

    const plansHere = site_settings?.plans?.filter((item, index) => item.mother.type == selectedCurrency)

    return (
        <SiteWide title={"Go global"}>
            <Section overlayText={loadingText} loading={loading} title={'Go global'}>



                <div class="alarm">
                   
                    {/*<input id="main-toggle" type="checkbox" class="tgl cursor-pointer"/><label for="main-toggle" class="toggle"></label>*/}
                    
                    <div class="alarm-content">
                        
                        <div class="calendar-wrap">
                            <div class="calendar">
                                <div class="calendar-actio clearfix">
                                    <div class="button" style={{width: '100%', }}>

                                        {site_settings?.plans?.map((item, index) => <label onClick={() => startTradeModal(item.mother.type)} for="main-toggle" class="text-dark bold btn btn-block pull-right">
                                            <RiAddBoxFill size={25} /> New {item?.mother?.abbr} Global</label>)}
                                            <label for="main-toggle" class="text-dark bold btn btn-block pull-right"><RiCloseLine size={25} /> Close</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="alarm-ul">

                            <div className="container" style={{}}>


                                <div className="" style={{backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                

                                    
                                    <div className="head text-info-1" style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    </div>



                                        
                                        {finance.map((finItem, finIndex) => {

                                            let mainEnjin = tradesData?.childs?.filter((innerBalaItem, ind) => (innerBalaItem.currencyId == finItem.type && innerBalaItem.active == 1) );
                                            let sumInTheTransaction = mainEnjin?.reduce((a, b) => Number(a) + Number(b?.capital), 0);
                                            let profits = mainEnjin?.reduce((a, b) => Number(a) + Number(b?.profit), 0);

                                            return <div className="container"><div className="ow" style={{backgroundColor: otherColors.cotpBlue, display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>

                                                <div className="ol-6" style={{padding: '30px 2px',}}>
                                                    <p className="text-dark-1">Total Trades</p>
                                                    <h6 className="text-dark-1" style={{fontSize: 12}}>{money(sumInTheTransaction ?? 0, finItem.type)}</h6>
                                                </div>

                                                
                                                <div className="ol-6 text-rigt" style={{padding: '30px 2px',}}>
                                                    <p className="text-dark-1">Profits</p>
                                                    <h6 className="text-dark-1" style={{fontSize: 12}}>{money(profits ?? 0, finItem.type)}</h6>
                                                </div>


                                                <div className="ol-6 text-rigt" style={{padding: '30px 2px',}}>
                                                    <p className="text-dark-1">Wallet</p>
                                                    <h6 className="text-dark-1" style={{fontSize: 12}}>{money(finItem?.balance ?? 0, finItem.type)}</h6>
                                                </div>

                                                <div onClick={() => startTradeModal(finItem.type)} className="ol-6 text-center cursor-pointer" style={{padding: '30px 2px', backgroundColor: otherColors.btn1}}>
                                                    <p className="text-dark">New {finItem.abbr}</p>
                                                    <h5 className="text-dark">Global</h5>
                                                </div>

                                            

                                            </div></div>

                                        })}








                                    <div>

                                        <div style={{backgroundColor: otherColors.card, color: '#fff', borderRadius: 10, marginTop: 60}} className="op-report">


                                            {tradeDataToShow?.map((item, index) => <div className="ba-bill-pay-inner">
                                                    <div className="ba-single-bill-pay">
                                                        {/*<div className="thumb">
                                                            <Images height={40} source={image?.[0]?.image} alt="img" />
                                                        </div>*/}
                                                        <div className="details" style={{width: '100%'}}>
                                                            <h5 style={{display: 'flex'}}>
                                                                <span>
                                                                    Trade
                                                                </span>
                                                                <span style={{marginLeft: 'auto'}}>
                                                                    {money(item.capital, item.type)}
                                                                </span>
                                                                {item.useTermination == 1 && <span>
                                                                    <Menu menuButton={<MenuButton style={{marginLeft: 10}}><RiMore2Fill /></MenuButton>} transition>
                                                                        <MenuItem onClick={() => terminateContract(item.id)} disabled={(item.terminated == 1 || item.active == 0)?true:false}>Terminate contract</MenuItem>
                                                                    </Menu>
                                                                </span>}
                                                            </h5>
                                                            <p style={{display: 'flex'}}>
                                                                <span>
                                                                    {item.txn_id}
                                                                </span>
                                                                <span style={{marginLeft: 'auto'}}>
                                                                    {item.isProcessed == 0?'':''}
                                                                </span>
                                                            </p>
                                                            <p>
                                                                {item?.active == 1 && <Countdown
                                                                    date={new Date(item?.timeToMaturedForCalculation)}
                                                                    renderer={({ hours, minutes, seconds, completed }) => {
                                                                      if (completed) {
                                                                        return <span></span>
                                                                      } else {
                                                                        return <span>{hours}:{minutes}:{seconds}</span>;
                                                                      }
                                                                    }}
                                                                  />}
                                                            </p>
                                                        </div>

                                                        

                                                    </div>
                                                    <div className="amount-inner">
                                                        <div style={{borderBottom: '1px solid #eee', paddingBottom: 10, display: 'flex', justifyContent: 'space-between'}}>
                                                            <h6>Date</h6>
                                                            <h6>{niceDate(item?.timeCreated)}</h6>
                                                        </div>
                                                        <div style={{borderBottom: '1px solid #eee', padding: '10px 0', display: 'flex', justifyContent: 'space-between'}}>
                                                            <h6>Profits</h6>
                                                            <h6>{money(item.profit, item.type)}</h6>
                                                        </div>
                                                        <div style={{paddingTop: 10, display: 'flex', justifyContent: 'space-between'}}>
                                                            <h6>Next time to earn </h6>
                                                            <h6>{niceDate(item?.timeToNExtMaturity)}</h6>
                                                        </div>
                                                    </div>
                                                </div>)}


                                        </div>







                                        {(tradeDataToShow?.length ?? 0)  < tradesData?.totalPages && <button style={{backgroundSize: 'cover', marginTop: 30, backgroundColor: otherColors.cotpBlue}} onClick = {referralData} className={`btn-block text-dark-1 btn cursor-pointer`}>
                                            Load more
                                        </button>}


                                    </div>




                                </div>
                            </div>

                        </div>

                    </div>
                </div>







                <Modal
                    isOpen={openTradeModal}
                    onRequestClose={() => setOpenTradeModal(false)}
                    contentLabel="New trade"
                    style={customNormalModalStyles}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                  >

                    <ModalCustomHead modalTitle={"New trade"} setOpenModal={() => setOpenTradeModal(false)}>

                        <div className="" style={{height: '60vh', display: 'flex', flexDirection: 'column', margin: 0, width: '100%'}}>

                            <div class="form-group">

                                <span className="text-info-1">Capital</span>
                                <input value={tradeCapital} onChange = {e => setTradeCapital(e.target.value) } required="" className="form-field form-control" type="text" />

                            </div>

                            <div class="form-group">

                                

                                

                                
                                

                            </div>

                            <div style={{marginTop: 'auto'}}>

                                {plansHere?.[0]?.childs.map((item, index) => <button onClick={() => tradeNow(item.type)}  class="name noselect arbi-button">{item.name}</button>)}


                            </div>

                        </div>

                    </ModalCustomHead>

                </Modal>







            </Section>
        </SiteWide>
    );

}

export default Arbitrage;