import React, {Component} from "react";
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {Images} from "./customComponents";

    

function Term(){

    const site_settings = useSelector(allRemoteSettingsSelect);


    function breakLine(text) {
        var br = React.createElement('br');
        var regex = /(<br \/>)/g;
        return text.split(regex).map(function(line, index) {
            return line.match(regex) ? <br key={"key_" + index} /> : line;
        });
    }


    return (
        <SiteWide id="main" title={"About us"}>
            <Section  hideHeader={true} title={'About'}>
                {/* transaction start */}
                <div className="transaction-area pd-top-36">
                    <div className="container">

                        <div className="about-content-inner">
                            <h5>Terms and condition</h5><br /><br />
                            <p>{breakLine(site_settings?.site_settings?.term)}</p>
                        </div>
                        
                    </div>
                </div>
                {/* transaction End */}
            </Section>
        </SiteWide>
    );

}

export default Term;